import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/web2/gatsby-starter-blog/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Marketing & Comms`}</h2>
    <p>{`I joined Exact while studying Business Administration. Initially tasked to support the marketing team, and then becoming the graphic designer for the Spanish offices I helped to update and localize sales and marketing material sent from the Netherlands HQ. `}</p>
    <p>{`My responsabilities grew with the website implementation where I took charge of the CMS, applying skills from SEO and SEM to improve positioning, handling emailings and sourcing customer data from our CRM. `}</p>
    <p>{`During my time there we also prepared marketing events (both customer facing and internal) and I designed the style for each.  `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      